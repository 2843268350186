const metroData = { 
    'Totals':['000s',15670,8036,2156,1716,4359,3677,5018,8312,5945,7696,7974,11439,2237,2404,919,3374,6348,7918,12626,12338,8610,13704,886,2288],
    '1':['000s',10185,5247,1115,853,2659,2588,3523,5552,4178,5160,5025,7700,1469,1606,618,2229,4274,5227,8284,8002,5749,8995,589,1607],
    '2':['000s',4523,2711,722,565,1534,1178,918,2364,1888,2441,2082,3231,785,830,306,1596,1968,2533,3719,3776,2437,3966,336,587],
    '3':['000s',4491,2607,592,426,1365,1242,1119,2320,1838,2289,2203,3237,674,746,283,1132,1925,2402,3658,3651,2409,3948,241,636],
    '4':['000s',2839,1766,381,295,966,801,599,1462,1198,1492,1347,2016,452,512,180,827,1223,1549,2293,2340,1511,2497,176,385],
    '1,2':['000s',11651,6107,1422,1106,3196,2911,3763,6263,4696,5892,5759,8677,1699,1859,713,2737,4858,5995,9460,9239,6494,10267,674,1764],
    '1,3':['000s',11735,6132,1398,1066,3184,2947,3845,6309,4733,5892,5843,8745,1691,1837,717,2625,4902,6026,9518,9273,6512,10343,668,1793],
    '1,4':['000s',11113,5816,1281,988,3013,2802,3689,5995,4515,5606,5508,8326,1609,1763,683,2503,4648,5706,8999,8771,6202,9800,643,1713],
    '2,3':['000s',7045,4152,1086,833,2311,1841,1536,3644,2878,3672,3373,5022,1152,1247,459,2083,3048,3846,5771,5811,3768,6174,463,930],
    '2,4':['000s',5926,3577,917,719,2028,1549,1217,3082,2467,3149,2777,4205,997,1082,390,1889,2569,3275,4849,4912,3176,5196,415,775],
    '3,4':['000s',5033,2901,643,467,1497,1404,1306,2618,2062,2548,2484,3626,747,827,316,1220,2134,2657,4076,4061,2706,4417,275,720],
    '1,2,3':['000s',12565,6610,1607,1246,3499,3111,3952,6699,4996,6311,6254,9273,1831,1995,766,2915,5237,6469,10202,9983,6934,11050,722,1873],
    '1,2,4':['000s',12128,6381,1514,1182,3373,3007,3857,6488,4855,6120,6008,8984,1775,1943,742,2838,5054,6245,9831,9623,6724,10671,704,1819],
    '1,3,4':['000s',11902,6221,1419,1085,3229,2992,3896,6398,4794,5970,5931,8863,1717,1865,724,2650,4966,6112,9642,9400,6601,10483,679,1815],
    '2,3,4':['000s',7490,4390,1130,869,2424,1966,1690,3887,3069,3887,3603,5333,1213,1312,487,2150,3222,4055,6116,6143,4010,6556,491,1001],
    '1,2,3,4':['000s',12703,6678,1628,1265,3537,3140,3996,6771,5050,6377,6327,9366,1855,2018,771,2932,5288,6540,10304,10085,7008,11164,733,1891],
    }
    const metroPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',65,65.29,51.72,49.71,61,70.38,70.21,66.79,70.28,67.05,63.02,67.31,65.67,66.81,67.25,66.06,67.33,66.01,65.61,64.86,66.77,65.64,66.48,70.24],
    '2':['Vert%',28.86,33.74,33.49,32.93,35.19,32.04,18.29,28.44,31.76,31.72,26.11,28.25,35.09,34.53,33.3,47.3,31,31.99,29.46,30.6,28.3,28.94,37.92,25.66],
    '3':['Vert%',28.66,32.44,27.46,24.83,31.31,33.78,22.3,27.91,30.92,29.74,27.63,28.3,30.13,31.03,30.79,33.55,30.32,30.34,28.97,29.59,27.98,28.81,27.2,27.8],
    '4':['Vert%',18.12,21.98,17.67,17.19,22.16,21.78,11.94,17.59,20.15,19.39,16.89,17.62,20.21,21.3,19.59,24.51,19.27,19.56,18.16,18.97,17.55,18.22,19.86,16.83],
    '1,2':['Vert%',74.35,76,65.96,64.45,73.32,79.17,74.99,75.35,78.99,76.56,72.22,75.85,75.95,77.33,77.58,81.12,76.53,75.71,74.92,74.88,75.42,74.92,76.07,77.1],
    '1,3':['Vert%',74.89,76.31,64.84,62.12,73.04,80.15,76.62,75.9,79.61,76.56,73.28,76.45,75.59,76.41,78.02,77.8,77.22,76.11,75.38,75.16,75.63,75.47,75.4,78.37],
    '1,4':['Vert%',70.92,72.37,59.42,57.58,69.12,76.2,73.52,72.12,75.95,72.84,69.07,72.79,71.93,73.34,74.32,74.18,73.22,72.06,71.27,71.09,72.03,71.51,72.57,74.87],
    '2,3':['Vert%',44.96,51.67,50.37,48.54,53.02,50.07,30.61,43.84,48.41,47.71,42.3,43.9,51.5,51.87,49.95,61.74,48.02,48.57,45.71,47.1,43.76,45.05,52.26,40.65],
    '2,4':['Vert%',37.82,44.51,42.53,41.9,46.52,42.13,24.25,37.08,41.5,40.92,34.83,36.76,44.57,45.01,42.44,55.99,40.47,41.36,38.4,39.81,36.89,37.92,46.84,33.87],
    '3,4':['Vert%',32.12,36.1,29.82,27.21,34.34,38.18,26.03,31.5,34.68,33.11,31.15,31.7,33.39,34.4,34.39,36.16,33.62,33.56,32.28,32.91,31.43,32.23,31.04,31.47],
    '1,2,3':['Vert%',80.19,82.25,74.54,72.61,80.27,84.61,78.76,80.59,84.04,82,78.43,81.06,81.85,82.99,83.35,86.4,82.5,81.7,80.8,80.91,80.53,80.63,81.49,81.86],
    '1,2,4':['Vert%',77.4,79.41,70.22,68.88,77.38,81.78,76.86,78.06,81.67,79.52,75.34,78.54,79.35,80.82,80.74,84.11,79.62,78.87,77.86,77.99,78.1,77.87,79.46,79.5],
    '1,3,4':['Vert%',75.95,77.41,65.82,63.23,74.08,81.37,77.64,76.97,80.64,77.57,74.38,77.48,76.75,77.58,78.78,78.54,78.23,77.19,76.37,76.19,76.67,76.5,76.64,79.33],
    '2,3,4':['Vert%',47.8,54.63,52.41,50.64,55.61,53.47,33.68,46.76,51.62,50.51,45.18,46.62,54.22,54.58,52.99,63.72,50.76,51.21,48.44,49.79,46.57,47.84,55.42,43.75],
    '1,2,3,4':['Vert%',81.07,83.1,75.51,73.72,81.14,85.4,79.63,81.46,84.95,82.86,79.35,81.88,82.92,83.94,83.9,86.9,83.3,82.6,81.61,81.74,81.39,81.47,82.73,82.65],
    }
    const nationalData = { 
    'Totals':['000s',22119,10939,2958,2338,5838,5101,7568,11814,8224,10886,11233,16433,2922,3205,1219,4320,8901,11022,17802,17086,12040,19201,1180,3369],
    '1':['000s',14007,7053,1550,1179,3539,3514,4985,7627,5645,7067,6941,10706,1910,2098,789,2822,5824,7110,11405,10876,7782,12279,782,2274],
    '2':['000s',5967,3506,947,740,1941,1565,1295,3102,2452,3217,2750,4342,978,1058,374,1912,2603,3286,4874,4872,3204,5188,420,823],
    '3':['000s',6015,3424,798,577,1777,1647,1570,3116,2420,3118,2896,4398,849,954,376,1365,2559,3174,4912,4784,3219,5256,341,873],
    '4':['000s',3664,2263,491,376,1228,1035,795,1886,1544,1953,1711,2648,560,628,246,979,1591,1989,2967,2952,1957,3202,227,520],
    '1,2':['000s',15997,8182,1954,1515,4214,3968,5367,8598,6334,8066,7931,12072,2205,2432,909,3436,6631,8154,13000,12501,8806,14000,886,2503],
    '1,3':['000s',16158,8235,1931,1463,4220,4015,5494,8688,6390,8114,8045,12196,2198,2415,929,3313,6698,8205,13123,12581,8864,14130,903,2539],
    '1,4':['000s',15255,7800,1767,1351,3991,3810,5233,8228,6088,7689,7565,11574,2090,2299,889,3158,6338,7746,12370,11868,8405,13348,855,2421],
    '2,3':['000s',9488,5487,1449,1107,2991,2496,2201,4915,3840,4991,4497,6888,1448,1603,594,2534,4085,5091,7739,7658,5068,8256,611,1333],
    '2,4':['000s',7838,4659,1198,931,2593,2067,1703,4068,3238,4179,3658,5676,1246,1365,498,2274,3406,4269,6382,6366,4203,6823,524,1099],
    '3,4':['000s',6722,3804,865,631,1955,1848,1818,3494,2704,3480,3242,4916,940,1055,416,1466,2843,3513,5460,5312,3613,5864,382,1000],
    '1,2,3':['000s',17304,8888,2204,1701,4627,4260,5669,9237,6768,8688,8616,12954,2378,2622,995,3663,7164,8816,14053,13533,9455,15109,965,2667],
    '1,2,4':['000s',16664,8568,2074,1608,4457,4111,5507,8923,6562,8399,8265,12524,2302,2539,964,3563,6909,8492,13516,13023,9140,14561,928,2589],
    '1,3,4':['000s',16392,8360,1959,1488,4284,4076,5570,8813,6470,8228,8163,12364,2234,2451,941,3344,6791,8323,13297,12756,8994,14321,917,2579],
    '2,3,4':['000s',10054,5797,1503,1152,3144,2653,2392,5218,4080,5276,4778,7295,1527,1683,627,2611,4316,5360,8181,8081,5380,8743,646,1436],
    '1,2,3,4':['000s',17497,8987,2228,1723,4683,4304,5732,9340,6838,8785,8711,13091,2411,2653,1004,3687,7241,8913,14196,13674,9562,15267,979,2701],
    }
    const nationalPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',63.33,64.48,52.4,50.43,60.62,68.89,65.87,64.56,68.64,64.92,61.79,65.15,65.37,65.46,64.73,65.32,65.43,64.51,64.07,63.65,64.63,63.95,66.27,67.5],
    '2':['Vert%',26.98,32.05,32.01,31.65,33.25,30.68,17.11,26.26,29.82,29.55,24.48,26.42,33.47,33.01,30.68,44.26,29.24,29.81,27.38,28.51,26.61,27.02,35.59,24.43],
    '3':['Vert%',27.19,31.3,26.98,24.68,30.44,32.29,20.75,26.38,29.43,28.64,25.78,26.76,29.06,29.77,30.84,31.6,28.75,28.8,27.59,28,26.74,27.37,28.9,25.91],
    '4':['Vert%',16.56,20.69,16.6,16.08,21.03,20.29,10.5,15.96,18.77,17.94,15.23,16.11,19.16,19.59,20.18,22.66,17.87,18.05,16.67,17.28,16.25,16.68,19.24,15.43],
    '1,2':['Vert%',72.32,74.8,66.06,64.8,72.18,77.79,70.92,72.78,77.02,74.1,70.6,73.46,75.46,75.88,74.57,79.54,74.5,73.98,73.03,73.17,73.14,72.91,75.08,74.3],
    '1,3':['Vert%',73.05,75.28,65.28,62.57,72.29,78.71,72.6,73.54,77.7,74.54,71.62,74.22,75.22,75.35,76.21,76.69,75.25,74.44,73.72,73.63,73.62,73.59,76.53,75.36],
    '1,4':['Vert%',68.97,71.3,59.74,57.78,68.36,74.69,69.15,69.65,74.03,70.63,67.35,70.43,71.53,71.73,72.93,73.1,71.21,70.28,69.49,69.46,69.81,69.52,72.46,71.86],
    '2,3':['Vert%',42.9,50.16,48.99,47.35,51.23,48.93,29.08,41.6,46.69,45.85,40.03,41.92,49.56,50.02,48.73,58.66,45.89,46.19,43.47,44.82,42.09,43,51.78,39.57],
    '2,4':['Vert%',35.44,42.59,40.5,39.82,44.42,40.52,22.5,34.43,39.37,38.39,32.56,34.54,42.64,42.59,40.85,52.64,38.27,38.73,35.85,37.26,34.91,35.53,44.41,32.62],
    '3,4':['Vert%',30.39,34.77,29.24,26.99,33.49,36.23,24.02,29.58,32.88,31.97,28.86,29.92,32.17,32.92,34.13,33.94,31.94,31.87,30.67,31.09,30.01,30.54,32.37,29.68],
    '1,2,3':['Vert%',78.23,81.25,74.51,72.75,79.26,83.51,74.91,78.19,82.3,79.81,76.7,78.83,81.38,81.81,81.62,84.79,80.49,79.99,78.94,79.21,78.53,78.69,81.78,79.16],
    '1,2,4':['Vert%',75.34,78.33,70.11,68.78,76.34,80.59,72.77,75.53,79.79,77.15,73.58,76.21,78.78,79.22,79.08,82.48,77.62,77.05,75.92,76.22,75.91,75.83,78.64,76.85],
    '1,3,4':['Vert%',74.11,76.42,66.23,63.64,73.38,79.91,73.6,74.6,78.67,75.58,72.67,75.24,76.45,76.47,77.19,77.41,76.29,75.51,74.69,74.66,74.7,74.58,77.71,76.55],
    '2,3,4':['Vert%',45.45,52.99,50.81,49.27,53.85,52.01,31.61,44.17,49.61,48.47,42.54,44.39,52.26,52.51,51.44,60.44,48.49,48.63,45.96,47.3,44.68,45.53,54.75,42.62],
    '1,2,3,4':['Vert%',79.1,82.16,75.32,73.7,80.22,84.38,75.74,79.06,83.15,80.7,77.55,79.66,82.51,82.78,82.36,85.35,81.35,80.87,79.74,80.03,79.42,79.51,82.97,80.17],
    }

    const regionalData = { 
    'Totals':['000s',6449,2903,802,622,1479,1424,2550,3501,2279,3190,3259,4994,685,802,300,946,2552,3104,5176,4748,3430,5497,294,1081],
    '1':['000s',3822,1806,435,326,880,926,1462,2075,1466,1907,1916,3006,441,492,171,593,1550,1883,3121,2874,2034,3284,194,667],
    '2':['000s',1444,795,225,175,407,388,377,738,564,775,668,1110,193,229,68,316,635,753,1155,1096,767,1222,83,237],
    '3':['000s',1523,817,206,151,412,405,450,796,582,830,694,1161,175,209,93,233,634,772,1254,1132,811,1309,101,237],
    '4':['000s',826,497,110,80,262,234,196,424,346,461,364,633,109,116,65,152,368,440,675,612,445,704,51,135],
    '1,2':['000s',4346,2075,533,409,1018,1058,1603,2335,1638,2175,2172,3395,506,573,196,699,1773,2159,3540,3262,2312,3733,212,739],
    '1,3':['000s',4424,2104,534,397,1036,1068,1649,2379,1657,2222,2202,3451,507,578,212,688,1796,2179,3605,3308,2352,3787,235,747],
    '1,4':['000s',4141,1985,486,363,977,1007,1544,2233,1573,2084,2058,3247,481,536,207,655,1690,2039,3371,3097,2203,3548,212,708],
    '2,3':['000s',2443,1334,363,274,679,655,665,1271,962,1319,1124,1865,296,356,135,452,1037,1246,1968,1847,1300,2083,148,402],
    '2,4':['000s',1912,1082,281,212,565,517,486,987,771,1030,881,1471,249,284,108,385,838,994,1534,1454,1027,1627,108,325],
    '3,4':['000s',1689,903,222,164,459,444,512,876,642,931,758,1290,193,227,100,246,709,855,1384,1250,906,1447,107,280],
    '1,2,3':['000s',4739,2278,597,455,1129,1149,1718,2538,1772,2376,2363,3681,547,627,229,749,1927,2347,3851,3549,2521,4059,243,794],
    '1,2,4':['000s',4536,2187,560,426,1083,1104,1650,2436,1707,2279,2257,3540,528,596,223,724,1855,2248,3685,3400,2416,3891,223,770],
    '1,3,4':['000s',4490,2139,540,403,1056,1084,1673,2415,1675,2258,2232,3501,516,585,218,694,1825,2211,3655,3357,2393,3838,238,764],
    '2,3,4':['000s',2564,1407,373,283,721,686,702,1331,1011,1390,1175,1962,314,371,140,461,1094,1305,2065,1938,1369,2187,155,435],
    '1,2,3,4':['000s',4793,2310,601,458,1146,1164,1736,2568,1788,2409,2385,3725,556,634,233,755,1953,2373,3892,3589,2554,4103,246,810],
    }
    const regionalPercentage = { 
    'Totals':['Vert%',100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100,100],
    '1':['Vert%',59.27,62.21,54.24,52.41,59.5,65.03,57.33,59.27,64.33,59.78,58.79,60.19,64.38,61.35,57,62.68,60.74,60.66,60.3,60.53,59.3,59.74,65.99,61.7],
    '2':['Vert%',22.39,27.39,28.05,28.14,27.52,27.25,14.78,21.08,24.75,24.29,20.5,22.23,28.18,28.55,22.67,33.4,24.88,24.26,22.31,23.08,22.36,22.23,28.23,21.92],
    '3':['Vert%',23.62,28.14,25.69,24.28,27.86,28.44,17.65,22.74,25.54,26.02,21.29,23.25,25.55,26.06,31,24.63,24.84,24.87,24.23,23.84,23.64,23.81,34.35,21.92],
    '4':['Vert%',12.81,17.12,13.72,12.86,17.71,16.43,7.69,12.11,15.18,14.45,11.17,12.68,15.91,14.46,21.67,16.07,14.42,14.18,13.04,12.89,12.97,12.81,17.35,12.49],
    '1,2':['Vert%',67.39,71.48,66.46,65.76,68.83,74.3,62.86,66.7,71.87,68.18,66.65,67.98,73.87,71.45,65.33,73.89,69.47,69.56,68.39,68.7,67.41,67.91,72.11,68.36],
    '1,3':['Vert%',68.6,72.48,66.58,63.83,70.05,75,64.67,67.95,72.71,69.66,67.57,69.1,74.01,72.07,70.67,72.73,70.38,70.2,69.65,69.67,68.57,68.89,79.93,69.1],
    '1,4':['Vert%',64.21,68.38,60.6,58.36,66.06,70.72,60.55,63.78,69.02,65.33,63.15,65.02,70.22,66.83,69,69.24,66.22,65.69,65.13,65.23,64.23,64.54,72.11,65.49],
    '2,3':['Vert%',37.88,45.95,45.26,44.05,45.91,46,26.08,36.3,42.21,41.35,34.49,37.34,43.21,44.39,45,47.78,40.63,40.14,38.02,38.9,37.9,37.89,50.34,37.19],
    '2,4':['Vert%',29.65,37.27,35.04,34.08,38.2,36.31,19.06,28.19,33.83,32.29,27.03,29.46,36.35,35.41,36,40.7,32.84,32.02,29.64,30.62,29.94,29.6,36.73,30.06],
    '3,4':['Vert%',26.19,31.11,27.68,26.37,31.03,31.18,20.08,25.02,28.17,29.18,23.26,25.83,28.18,28.3,33.33,26,27.78,27.55,26.74,26.33,26.41,26.32,36.39,25.9],
    '1,2,3':['Vert%',73.48,78.47,74.44,73.15,76.34,80.69,67.37,72.49,77.75,74.48,72.51,73.71,79.85,78.18,76.33,79.18,75.51,75.61,74.4,74.75,73.5,73.84,82.65,73.45],
    '1,2,4':['Vert%',70.34,75.34,69.83,68.49,73.23,77.53,64.71,69.58,74.9,71.44,69.25,70.89,77.08,74.31,74.33,76.53,72.69,72.42,71.19,71.61,70.44,70.78,75.85,71.23],
    '1,3,4':['Vert%',69.62,73.68,67.33,64.79,71.4,76.12,65.61,68.98,73.5,70.78,68.49,70.1,75.33,72.94,72.67,73.36,71.51,71.23,70.61,70.7,69.77,69.82,80.95,70.68],
    '2,3,4':['Vert%',39.76,48.47,46.51,45.5,48.75,48.17,27.53,38.02,44.36,43.57,36.05,39.29,45.84,46.26,46.67,48.73,42.87,42.04,39.9,40.82,39.91,39.79,52.72,40.24],
    '1,2,3,4':['Vert%',74.32,79.57,74.94,73.63,77.48,81.74,68.08,73.35,78.46,75.52,73.18,74.59,81.17,79.05,77.67,79.81,76.53,76.45,75.19,75.59,74.46,74.64,83.67,74.93],
    }

const graph_source = 'Nielsen CMV, National Survey 6 2024'

const exportedObject = {
    metroData,
    metroPercentage,
    nationalData,
    nationalPercentage,
    regionalData,
    regionalPercentage,
    graph_source

}

export default exportedObject


    